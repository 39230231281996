import axiosApi from "axios";
// import store from "../store";

const axios = axiosApi.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    // Accept: "application/json",
    Authorization: localStorage.getItem("accessToken"),
  },
});

export default axios;
