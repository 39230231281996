import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/",
    component: () => import("@/views/container.vue"),
    children: [
      {
        path: "/",
        name: "main",
        component: () => import("@/views/main.vue"),
      },

      // {
      //   path: "/film",
      //   name: "film",
      //   component: () => import("@/views/Film.vue"),
      // },
      // {
      //   path: "/branding",
      //   name: "branding",
      //   component: () => import("@/views/Branding.vue"),
      // },
      // {
      //   path: "/editorial",
      //   name: "editorial",
      //   component: () => import("@/views/Editorial.vue"),
      // },
      {
        path: "/adminBoard",
        name: "adminBoard",
        component: () => import("@/views/AdminBoard.vue"),
      },
      {
        path: "/:category",
        name: "boardList",
        component: () => import("@/views/categoryView.vue"),
      },
      {
        path: "/:category/view/:id",
        name: "contentsView",
        component: () => import("@/views/ContentsView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      console.log(to);
      return {
        selector: to.hash,
      };
    }
    if (savedPosition) {
      console.log(savedPosition);
      // return savedPosition;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 100);
      });
    }

    return { x: 0, y: 0 };
  },
});

export default router;
