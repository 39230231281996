import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./mixins/axios";

//import css
import "@/style/common.scss";
import "@/style/nav.scss";
import "@/style/footer.scss";

// Vue.prototype.$baseURL = "http://localhost:3000";
// Vue.prototype.$baseURL = process.env.VUE_APP_API_ENDPOINT;

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
